import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "expandedIcon", "collapsedIcon"];

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    this.expandedIconTarget.classList.toggle("hidden");
    this.collapsedIconTarget.classList.toggle("hidden");
  }
}
