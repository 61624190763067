import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["addedUsers"];

  connect() {
    this.initializeController();

    document.addEventListener(
      "turbo:frame-load",
      this.initializeController.bind(this),
    );
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }

    document.removeEventListener(
      "turbo:frame-load",
      this.initializeController.bind(this),
    );
  }

  initializeController() {
    if (!this.hasAddedUsersTarget) {
      return;
    }

    this.checkUsersPresence();
    this.observeAddedUsers();
  }

  checkUsersPresence() {
    const userElements = this.addedUsersTarget.querySelectorAll("[data-user]");
    const hasUsers = userElements.length > 0;
    const separator = document.getElementById("user_selection_separator");

    if (separator) {
      if (hasUsers) {
        separator.classList.remove("hidden");
      } else {
        separator.classList.add("hidden");
      }
    }
  }

  observeAddedUsers() {
    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          this.checkUsersPresence();
        }
      });
    });

    this.observer.observe(this.addedUsersTarget, {
      childList: true,
      subtree: true,
    });
  }
}
