import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search-form"
export default class extends Controller {
  search(event) {
    event.preventDefault();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 200);
  }

  clear(event) {
    event.preventDefault();
    this.element.reset();
    this.element.requestSubmit();
  }
}
