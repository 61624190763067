import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["eventInlineForm", "calendar"];

  toggleEventInlineForm() {
    this.eventInlineFormTarget.classList.toggle("hidden");
    if (!this.eventInlineFormTarget.classList.contains("hidden")) {
      this.calendarTarget.classList.add("hidden");
    }
  }

  toggleCalendar() {
    this.calendarTarget.classList.toggle("hidden");
    if (!this.calendarTarget.classList.contains("hidden")) {
      this.eventInlineFormTarget.classList.add("hidden");
    }
  }
}
