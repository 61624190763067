import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "alert",
    "hintsNumberOfCharacters",
    "hintsNumbers",
    "hintsLetters",
    "hintsSpecialCharacters",
  ];

  validatePassword() {
    this.validateLength(this.inputTarget.value);
    this.validateNumbers(this.inputTarget.value);
    this.validateLetters(this.inputTarget.value);
    this.validateSpecialCharacters(this.inputTarget.value);
  }

  validateLength(value) {
    if (value.length >= 8) {
      this.showSuccessHint(this.hintsNumberOfCharactersTarget);
    } else {
      this.showAttentionHint(this.hintsNumberOfCharactersTarget);
    }
  }

  validateNumbers(value) {
    if (value.match(/.*[0-9].*/)) {
      this.showSuccessHint(this.hintsNumbersTarget);
    } else {
      this.showAttentionHint(this.hintsNumbersTarget);
    }
  }

  validateLetters(value) {
    if (value.match(/.*[a-zA-Z].*[a-zA-Z].*/)) {
      this.showSuccessHint(this.hintsLettersTarget);
    } else {
      this.showAttentionHint(this.hintsLettersTarget);
    }
  }

  validateSpecialCharacters(value) {
    if (value.match(/.*[!@#$%^&*()_+\-={};:,.<>?].*/)) {
      this.showSuccessHint(this.hintsSpecialCharactersTarget);
    } else {
      this.showAttentionHint(this.hintsSpecialCharactersTarget);
    }
  }

  showSuccessHint(target) {
    target.classList.add("hidden");
    this.hideAlertIcon();
  }

  showAttentionHint(target) {
    target.classList.remove("hidden");
    this.showAlertIcon();
  }

  showAlertIcon() {
    this.alertTarget.classList.remove("hidden");
    this.alertTarget.classList.add("flex");
  }

  hideAlertIcon() {
    this.alertTarget.classList.add("hidden");
    this.alertTarget.classList.remove("flex");
  }
}
