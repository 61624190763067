import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["installPanel", "installButton", "safariInstallButton"];

  installationKey = "calendar-ios-install-modal-shown";
  installPrompt = null;

  connect() {
    if (this.isPWA()) return;

    this.showSafariInstallPrompt(this.installationKey);

    window.addEventListener("beforeinstallprompt", (event) =>
      this.berforeInstallPrompt(event),
    );
  }

  disconnect() {
    window.removeEventListener("beforeinstallprompt", (event) =>
      this.berforeInstallPrompt(event),
    );
  }

  berforeInstallPrompt(event) {
    event.preventDefault();
    this.installPrompt = event;
    this.showInstallPrompt();
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  isInStandaloneMode() {
    return "standalone" in window.navigator && window.navigator.standalone;
  }

  isInstallPageForSafari() {
    const currentURL = new URL(window.location.href);
    return (
      currentURL.pathname === "/install" &&
      currentURL.searchParams.get("platform") === "ios_safari"
    );
  }

  isPWA() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  }

  showInstallPrompt() {
    this.installPanelTarget.classList.remove("hidden");
    this.installPanelTarget.classList.add("flex");

    this.installButtonTarget.classList.remove("hidden");
  }

  showSafariInstallPrompt(localStorageKey) {
    const localStorageKeyValue = localStorage.getItem(localStorageKey);
    const iosInstallModalShown = localStorageKeyValue
      ? JSON.parse(localStorageKeyValue)
      : false;
    const shouldShowModalResponse =
      this.isSafari() &&
      !this.isInStandaloneMode() &&
      !this.isInstallPageForSafari() &&
      !iosInstallModalShown;

    if (shouldShowModalResponse) {
      this.installPanelTarget.classList.remove("hidden");
      this.installPanelTarget.classList.add("flex");
      this.safariInstallButtonTarget.classList.remove("hidden");
    }
  }

  async install() {
    if (this.installPrompt) {
      const result = await this.installPrompt.prompt();

      if (result.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      }
      this.cancel();
    } else {
      console.log("No installation event available.");
    }
  }

  installSafari() {
    localStorage.setItem(this.installationKey, JSON.stringify(true));
    this.cancel();
  }

  cancel() {
    this.installPanelTarget.classList.remove("flex");
    this.installPanelTarget.classList.add("hidden");
  }
}
