import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  openModal(event) {
    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("block");
  }

  closeModal(event) {
    this.modalTarget.classList.add("hidden");
    this.modalTarget.classList.remove("block");
  }
}
