import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option"];

  select(event) {
    this.optionTargets.forEach((el) => {
      el.classList.toggle("ring-2", event.target.parentElement == el);
    });
  }
}
