import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "typeDescriptionContent",
    "typeDescriptionCollapsedIcon",
    "typeDescriptionExpandedIcon",
    "optionalFieldsContent",
    "optionalFieldsCollapsedIcon",
    "optionalFieldsExpandedIcon",
  ];

  connect() {
    this.clearToggleStates();
    this.initializeToggleState();
  }

  clearToggleStates() {
    localStorage.removeItem("typeDescriptionToggle");
    localStorage.removeItem("optionalFieldsToggle");
  }

  initializeToggleState() {
    const typeDescriptionToggleState =
      localStorage.getItem("typeDescriptionToggle") || "collapsed";
    if (typeDescriptionToggleState === "expanded") {
      this.showTypeDescriptionContent();
    } else {
      this.hideTypeDescriptionContent();
    }

    const optionalFieldsState =
      localStorage.getItem("optionalFieldsToggle") || "collapsed";
    if (optionalFieldsState === "expanded") {
      this.showOptionalFieldsContent();
    } else {
      this.hideOptionalFieldsContent();
    }
  }

  toggleTypeDescription() {
    if (this.typeDescriptionContentTarget.classList.contains("hidden")) {
      this.showTypeDescriptionContent();
      localStorage.setItem("typeDescriptionToggle", "expanded");
    } else {
      this.hideTypeDescriptionContent();
      localStorage.setItem("typeDescriptionToggle", "collapsed");
    }
  }

  toggleOptionalFields() {
    if (this.optionalFieldsContentTarget.classList.contains("hidden")) {
      this.showOptionalFieldsContent();
      localStorage.setItem("optionalFieldsToggle", "expanded");
    } else {
      this.hideOptionalFieldsContent();
      localStorage.setItem("optionalFieldsToggle", "collapsed");
    }
  }

  showTypeDescriptionContent() {
    this.typeDescriptionContentTarget.classList.remove("hidden");
    this.typeDescriptionExpandedIconTarget.classList.remove("hidden");
    this.typeDescriptionCollapsedIconTarget.classList.add("hidden");
  }

  hideTypeDescriptionContent() {
    this.typeDescriptionContentTarget.classList.add("hidden");
    this.typeDescriptionExpandedIconTarget.classList.add("hidden");
    this.typeDescriptionCollapsedIconTarget.classList.remove("hidden");
  }

  showOptionalFieldsContent() {
    this.optionalFieldsContentTarget.classList.remove("hidden");
    this.optionalFieldsExpandedIconTarget.classList.remove("hidden");
    this.optionalFieldsCollapsedIconTarget.classList.add("hidden");
  }

  hideOptionalFieldsContent() {
    this.optionalFieldsContentTarget.classList.add("hidden");
    this.optionalFieldsExpandedIconTarget.classList.add("hidden");
    this.optionalFieldsCollapsedIconTarget.classList.remove("hidden");
  }
}
